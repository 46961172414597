import styled from "styled-components";


const TimelineStyle = styled.div` 
    .exp-holder{
        margin-top: 20px;
    }
    &:before{
        background: #ededed;
        background: linear-gradient(to bottom, #ededed 1%,#ededed 75%,#ffffff 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ededed', endColorstr='#ffffff',GradientType=0 );
        bottom: 0;
        content: "";
        left: 18px;
        position: absolute;
        top: 0;
        width: 3px;
        height: 100%;
    }
    &:after{
        content: '#';
        background: #ffffff;
        border: 3px solid #efefef;
        font-family: 'fontello';
        top: 0;
        left: -5px;
        width: 50px;
        height: 50px;
        position: absolute;
        text-align: center;
        color: #333333;
        font-size: 20px;
        line-height: 45px;
        border-radius: 100%;
    }

`

export default TimelineStyle;