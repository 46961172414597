import styled from "styled-components";

const TimelineExpStyle = styled.div` 
    .exp{
        padding-left: 50px;
        padding-top: 20px;
        position: relative;
        margin-bottom: 10px;
        &:after{
            border-radius:100%;
            background: ${props => props.theme.redColor};
            border: 3px solid #ffffff;
            content: "";
            height: 15px;
            left: 12px;
            position: absolute;
            text-align: center;
            top: 24px;
            width: 15px;
        }
        .hgroup{
            margin-bottom:5px;

            h1{
                font-size:16px;
            }
            h2{
                font-weight:normal;
                padding-top:6px;
                font-size:14px;
                span{                    
                    padding-left:5px;

                    &.current-label{                        
                        padding:0;
                        color:#ffffff;
                        background: ${props => props.theme.redColor};
                        border-radius:10px;
                        padding:1px 10px 1px 8px;
                        font-weight:normal;                        
                        font-size:12px;
                    }
                }
            }
        }
        p{
            @media(min-width:992px){
                padding-right: 10%;
            }
        }
    }


`

export default TimelineExpStyle;