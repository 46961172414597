import React from 'react'
import TimelineStyle from './timeline.style'
import TimelineExperience from './Timeline-experience/index'

const Timeline = ({ data }) => {
    
    return (

        <TimelineStyle>
            <div className="exp-holder">
                {data.map((exp, key) => (
                    <TimelineExperience key={key} title={exp.title} from={exp.from} to={exp.to} company={exp.Company}>
                        {exp.description}
                    </TimelineExperience>
                ))}
            </div>
        </TimelineStyle>

    )
}

export default Timeline
