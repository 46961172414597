import React from "react";
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby'
import { Row, Col } from '../../UI/Grid/index'
import CvHeaderStyle from './header.style'
import { IoMdPerson, IoMdCode, IoIosBook, IoMdBriefcase } from "react-icons/io"
import { FaLinkedinIn, FaXing, FaTwitter, FaSkype } from "react-icons/fa"

const CvHeader = () => {

  const Socials = [
    {
      icon: <FaLinkedinIn size={'1rem'} />,
      link: '#'
    },
    {
      icon: <FaXing size={'1rem'} />,
      link: '#'
    },
    {
      icon: <FaTwitter size={'1rem'} />,
      link: '#'
    },
    {
      icon: <FaSkype size={'1rem'} />,
      link: '#'
    }
  ]

  const Nav = [
    {
      icon: <IoMdPerson size={'1rem'} />,
      title: 'About',
      link: '#who-am-i'
    },
    {
      icon: <IoMdCode size={'1rem'} />,
      title: 'Professional Skills',
      link: '#professional-skills'
    },
    {
      icon: <IoMdBriefcase size={'1rem'} />,
      title: 'Experience',
      link: '#experience'
    },
    {
      icon: <IoIosBook size={'1rem'} />,
      title: 'Education',
      link: '#education'
    },
  ]

  const data = useStaticQuery(graphql`
  query {
    me:file(relativePath: {eq: "me/me_3.jpg"}) {
      childImageSharp {
        fluid(maxWidth:300, quality:80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`)

  return (
    <>
      <CvHeaderStyle>
        <Img alt="meportrait" fluid={data.me.childImageSharp.fluid} style={{marginBottom:'-10px'}} />
        <ul className="largeMenu">
          {Nav.map((n, key) => (
            <li key={key}>
              <Row>
                <Col sm={2} md={2} lg={3}>
                  <a href={n.link}>{n.icon && n.icon}</a>
                </Col>
                <Col sm={10} md={10} lg={9}>
                  <a className="title" href={n.link}>
                    {n.title}
                  </a>
                </Col>
              </Row>
            </li>
          ))}
        </ul>

        {/* <div className="socials-wrapper">
          {Socials.map((social, key) => (
            <span key={key}>
              <a href={social.link}>{social.icon && social.icon}</a>
            </span>
          ))}
        </div> */}
      </CvHeaderStyle>

    </>
  );
};

export default CvHeader;
