import React from "react";
import CvContainerStyle from './cv.style'
import CvHeader from "./Cv-header/index";
import CvMain from "./Cv-main/index";
import { Row, Col } from "../../components/UI/Grid/index";

const CvContainer = () => {

  return (
    <CvContainerStyle>
      <Row>
        <Col style={{position:'relative'}} lg={3} md={3} sm={12} >
          <CvHeader />
        </Col>
        <Col lg={8} md={9} sm={12} >
          <CvMain />
        </Col>
      </Row>



    </CvContainerStyle>
  );
};

export default CvContainer;
