import styled from "styled-components";

const CvSectionStyle = styled.section`   
    padding:40px 6% 40px 3%; 
    
    h1{
        font-size:22px;
    }
    h3{
        padding-top:6px;
        font-size:20px;
        font-weight:400;
    }
    p{        
        font-size:14px;
        text-align: justify;
        line-height:22px;
    }
    table{
        tr{
            th,td{
                padding:10px;
                text-align:center;
                font-size:14px;
            }
            th{
                border-left: 1px solid #ccc;
                border-top:1px solid #ccc;
                border-right:1px solid #ccc;
            }
            td{
                color:#666666;
                background:#fafafa;
                border-top:1px solid #ccc;
                border-right:1px solid #ccc;
            }
        }
    }
    .qrcode-wrapper{
        position:relative;    
        display:block;
        max-width:250px; 
        span{
            position:absolute;
            z-index:5;
            font-size:14px;
            background:#fff;
            padding:0 10px; 
            left:50%;
            transform:translate(-50%,0); 
            &:first-child{
                top:-12px;

            }
            &:nth-child(2){
                bottom:-10px;                                
            }
       
        }
        
        .gatsby-image-wrapper{
            border:5px solid #ccc;
            width:100%;    
            
            img{
                padding:16px;
            }
        }
    }
    ul{
        margin:0;
        li{
            display: inline; /* li nebeneinander anzeigen */
            list-style-type: none; /* ohne Aufzählungspunkte */
            margin-right:20px
        }
    }
`

export default CvSectionStyle;