import styled from "styled-components";

const CvContainerStyle = styled.section`    
    height:100%;
    width:90%;
    border-radius: 5px;
    margin:0 auto;    

    @media (min-width:1980px){
        width:70%;
    }
`

export default CvContainerStyle;