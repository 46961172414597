import React from "react";
import CvSectionStyle from './section.style'
import CvSectionHeader from '../Cv-section-header/index'

import Fade from 'react-reveal/Fade'

const CvSection = (props) => {


    return (
        <Fade>
            <CvSectionHeader title={props.title} linkId={props.linkId} />
            <CvSectionStyle style={props.style}>
                {props.children}
            </CvSectionStyle>
        </Fade>
    );
};

export default CvSection;
