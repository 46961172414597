import React from 'react'
import Helmet from 'react-helmet';
import Layout from '../components/UI/Layout/layout'
import SEO from "../components/seo"
import { IoMdClose } from "react-icons/io";
import Particle from "../components/UI/Particle/index";
import CvContainer from '../components/Cv-container/index';
import { Link } from 'gatsby';

const Skillset = (props) => {

    const closeModal = () => {
        // typeof history !== 'undefined' && history.go(-1)
    }

    return (

        <Layout modal={true}>
            <SEO
                metaDescription='Philipp Rhönisch - Freiberuflicher App & Web Developrer. Dein Transparenter Partner für App & Web Applikationen'
                metaTitle="Lebenslauf - Meine Persönlicher Lebenslauf" />
            <Particle />
            <Link to="" style={{ textAlign: 'right', padding: '20px', display:'block' }} >
                <IoMdClose style={{ cursor: 'pointer' }} className="" onClick={() => closeModal()} size={'4em'} />
            </Link>
            <CvContainer />
        </Layout>
    )
}

export default Skillset;



