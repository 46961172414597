import styled from "styled-components";

const CvSectionHeaderStyle = styled.section`    
    width:100%;
    border-bottom:1px solid #ccc;
    position:relative;
    padding:30px 5% 20px 3%;

    .divider{
        left: 2.3%;
        position: absolute;
        top: 55px;
        background: #fff;
        padding: 0 10px;
        .zigzag{
            fill: none;
            stroke-miterlimit: 10;
            stroke: ${props => props.theme.redColor};
            stroke-width: 4.3;
        }
    }
`

export default CvSectionHeaderStyle;