import styled from "styled-components";

const CvHeaderStyle = styled.section`
    position:relative;
    width:100%;
    height:auto;
    box-shadow: 0px -1px 19px 0px rgba(0,0,0,0.1);

    @media(min-width:768px){
        max-width:260px;
        position:absolute;
        right:6px;
    }
    img{
        transform: scaleX(-1);
    }
    .socials-wrapper{
        background:#fff;
        padding:10px 10px 0 10px;
        text-align:center;

        span{
            padding-left:6px;
            svg{
                border: 2px solid #33373d;
                color: #33373d;
                border-radius:100%;
                padding:10px;
                height: 40px;
                width: 40px;
                overflow:visible;
                display: inline-block;
                transition:all .5s ease;

                &:hover{
                    color:${props => props.theme.redColor};
                    border: 2px solid ${props => props.theme.redColor};
                }
            }
        }
    }

    ul{

        &.largeMenu{
            @media(max-width:600px){
                display:none;
            }
        }

        padding: 0;
        margin:0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-decoration: none;
        width:100%;
        li{
            background: #33373d;
            position: relative;
            border-bottom: 1px solid #26292e!important;
            width:100%;
            margin: 0;
            padding: 10px 0;
            border: 0;            
            vertical-align: baseline;
            text-decoration: none;
            list-style:none;
            &:hover{
                background: ${props => props.theme.redColor};
                a{
                    background: ${props => props.theme.redColor};;
                    color: #fff;
                }
            }
            a{
                &.title{
                    text-align: right;
                    padding-right:30px;
                }
                background: #33373d;
                color: #85878b;
                font-size: 12px;
                text-transform: uppercase;
                width: 100%;
                display:block;

                svg{                
                    left:35px;
                    position: absolute;
                    top: 50%;
                    transform: translate(0,-50%);
                }
            }
        }
    }
`

export default CvHeaderStyle;