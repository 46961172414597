import React from 'react'
import TimelineExpStyle from "./timelineExp.style"
import { FaCalendarAlt, FaMapPin } from 'react-icons/fa'

const TimelineExperience = (props) => {

    return (
        <TimelineExpStyle>
            <div className="exp">
                <div className="hgroup">
                    <h1>{props.title}</h1>

                    {props.company &&
                        <h2>
                            <FaMapPin />
                            <span>{props.company.name}</span>
                        </h2>}
                    <h2>
                        <FaCalendarAlt />
                        <span className="time">{props.from} - {props.to ? props.to :(<span className="current-label">Current</span>)}</span>
                    </h2>
                </div>
                <p>{props.children}</p>
            </div>
        </TimelineExpStyle>
    )
}

export default TimelineExperience
