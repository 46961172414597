import styled from "styled-components";

const CvHeaderStyle = styled.section`
    background:#fff;
    padding:0 0 100px;
    box-shadow: 0px -1px 19px 0px rgba(0,0,0,0.1);

    @media(max-width:768px){
        margin-top:20px;
    }

    .skill-tags{
        li{
            .skill-name{
                font-size:12px;
            }
            text-transform:uppercase;
            display: block;            
            .exp-balken{
                position:relative;
                width:100%;
                border:1px solid #ccc;
                height:20px;
                border-radius:10px;
                overflow:hidden;

                span{
                    position: absolute;
                    font-size:10px;

                    &.left-years{
                        top: -3px;
                        left: 7px;
                    }
                    &.right-years{
                        right:7px;
                        top: -3px
                    }
                }

                div{
                    height:100%;
                    background:${props => props.theme.secondaryLightColor};
                    border-right:1px solid #ccc;

                    span{
                        height: 100%;
                        border-right: 1px dotted lightgray;
                        &:nth-child(1){
                            width:25%;                                
                        }
                        &:nth-child(2){
                            width:50%
                        }
                        &:nth-child(3){
                            width:75%
                        }
                    }
                }
            }
        }
    }

    .skill-category-col{
        @media(min-width:768px){
            transform:rotate(90deg);    
        }
        .skill-category{
            border: 1px dotted grey;
            padding: 3px 5px;
            display:inline;
            @media(min-width:768px){
                display:block;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                white-space: nowrap;            
            }
        }
    }

`

export default CvHeaderStyle;