import React from "react";
import CvMainStyle from './main.style'
import CvSection from "./Cv-section/index";
import Timeline from "../../Timeline/index"
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from "gatsby"
import { Row, Col } from "../../UI/Grid"
import { IoLogoIonic } from "react-icons/io"
import { FaReact, FaAngular } from "react-icons/fa"

const CvMain = (props) => {

  const data = useStaticQuery(graphql`
    query {
      cvWrapper: strapiCv {
        experience: WorkExperience {
          from(locale: "de", formatString: "MMMM YYYY")
          to(locale: "de", formatString: "MMMM YYYY")
          where              
          title
          id       
          description 
          Company {
            name
            email               
          }
        }
        education: Education {
          from(locale: "de", formatString: "MMMM YYYY")
          to(locale: "de", formatString: "MMMM YYYY")
          where              
          title
          id       
          description     
          Company {
            name
            email               
          }
        }
      }
      about: strapiAbout {
        name
        city
        country
        email
        geb
        hello_text
        telefon
        street
        post_code
        work_title
        hourly_rate
      }
      qrcode:file(relativePath: {eq: "qrcode/qrcode.jpeg"}) {
        childImageSharp {
          fluid(maxWidth:300, quality:100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
`)
  const { experience, education } = data.cvWrapper;
  const { about } = data;
  const { qrcode } = data;

  const Skills = [
    {
      category: 'App',
      factor: 6,
      cat_exp_type_label: 'Apps',
      skills: [
        {
          name: 'Ionic Framework (auf Angular Basis)',
          icon: <IoLogoIonic size={'1rem'} />,
          exp_in_years_or_apps: 8,
        },
        {
          name: 'React Native',
          icon: <FaReact size={'1rem'} />,
          exp_in_years_or_apps: 3
        },
      ]
    },
    {
      category: 'Javascript',
      factor: 5,
      cat_exp_type_label: 'Jahre',
      skills: [
        {
          name: 'Angular JS',
          icon: <FaAngular size={'1rem'} />,
          exp_in_years_or_apps: 5
        },
        {
          name: 'React JS',
          icon: <FaReact size={'1rem'} />,
          exp_in_years_or_apps: 4
        },
        {
          name: 'Node JS',
          icon: <FaReact size={'1rem'} />,
          exp_in_years_or_apps: 2
        }
      ]
    }
  ]

  return (
    <CvMainStyle>

      <CvSection title='Who am i' linkId='who-am-i'>
        <h1>{about.name}</h1>
        <h3>{about.work_title}</h3>
        <br></br>
        <p>{about.hello_text}</p>
        <br></br>
        <Row>
          <Col sm={12} lg={6} md={12}>
            <table style={{ overflowX: 'auto' }}>
              <tbody>
                <tr>
                  <th>Name</th>
                  <td>{about.name}</td>
                </tr>
                <tr>
                  <th>Geburtsdatum</th>
                  <td>{about.geb}</td>
                </tr>
                <tr>
                  <th>Addresse</th>
                  <td>{`${about.street}, ${about.post_code} ${about.city}`}</td>
                </tr>
                <tr>
                  <th>E-Mail</th>
                  <td>{about.email}</td>
                </tr>
                <tr>
                  <th>Telefon</th>
                  <td>{about.telefon}</td>
                </tr>
                <tr>
                  <th>Stundenlohn</th>
                  <td>{about.hourly_rate} €</td>
                </tr>
              </tbody>
            </table>
          </Col>

          <Col sm={12} lg={6} md={12}>
            <div>
              <div className="qrcode-wrapper">
                <span>
                  Scan vCard
            </span>
                <span>
                  to contact me
            </span>
                <Img alt="qrcode" fluid={qrcode.childImageSharp.fluid} />
              </div>
            </div>
          </Col>

        </Row>

      </CvSection>

      <CvSection title='Professional Skills' linkId='professional-skills'>

        {Skills.map((cat, key) => (
          <Row key={key} style={{ marginBottom: '20px' }}>
            <Col lg={11} md={11} sm={12}>

              <ul className="skill-tags">
                {cat.skills.map((c, k) => (
                  <li key={k}>
                    <span className="skill-name">{c.name}</span>
                    <div className="exp-balken">
                      {/* <span className="left-years">0 {cat.cat_exp_type_label}</span> */}
                      <span className="right-years">{cat.factor}+ {cat.cat_exp_type_label}</span>
                      <div style={{ width: `${((c.exp_in_years_or_apps / cat.factor) * 100)}%` }}>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </Col>
            <Col className="skill-category-col" lg={1} md={1} sm={12}>
              <div className="skill-category">
                {cat.category}
              </div>
            </Col>
          </Row>
        ))}
      </CvSection>

      {/* <CvSection title='Services & Frameworks' linkId='experience'>

      </CvSection> */}

      <CvSection style={{ marginLeft: '3%', marginTop: '40px', paddingLeft: '0' }} title='Experience' linkId='experience'>
        <Timeline data={experience} />
      </CvSection>

      <CvSection style={{ marginLeft: '3%', marginTop: '40px', paddingLeft: '0' }} title='Education' linkId='education'>
        <Timeline data={education} />
      </CvSection>

    </CvMainStyle>
  );
};

export default CvMain;


