import React from "react";
import SVG from 'react-inlinesvg';
import CvSectionHeaderStyle from './header.style'
import dividerSvg from '../../../../assets/images/cv/divider.svg'

const CvSectionHeader = (props) => {
    
  
    return (
        <CvSectionHeaderStyle>
            <h4 id={props.linkId}>
                {props.title}
            </h4>
            <div className="divider">
                <div className="zigzag large clearfix " data-svg-drawing="yes">
                    <SVG src={dividerSvg}/>
                </div>
            </div>
        </CvSectionHeaderStyle >
    );
};

export default CvSectionHeader;
